import "./About.scss";

const About = () => {
  const pointData = [
    "Desain Mewah dan Modern",
    "Lokasi Strategis",
    "Penawaran Menarik",
    "Pengembang Terpercaya",
    "Fasilitas Lengkap",
  ];
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282332091818&text=Halo+Anthony,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Perumahan%20South%20Tresor)%20https://southtresormetland.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section className="section-about" id="about">
      <div className="container-about">
        <div className="caption">Citra Garden City</div>
        <h2 className="title">EXTRAORDINARY TOWNSHIP IN WEST JAKARTA</h2>
        <p className="description">
          Citra Garden City adalah kawasan permukiman terintegrasi yang terletak
          di Cengkareng dan Kalideres sub-districts, Jakarta Barat, Indonesia.
          Citra Garden City di kembangkan oleh Ciputra Group dengan luas tanah
          sebesar 450 hektar. Citra Garden City menawarkan konsep rumah yang
          terintergrasi dengan memadukan fungsi dan kemewahan sehinggan
          memberikan suasana yang menyegarkan dan tenang. Selain menawarkan
          konsep rumah yang mewah dan terintergrasi Citra Garden City juga
          menawarkan fasilitas di dalam cluster yang tidak kalah mewah Citra
          Garden City juga memiliki Club House, Sport Center, serta memiliki
          danau di dalam kawasan yang memberikan suasana yang menyegarkan.
        </p>
      </div>
    </section>
  );
};

export default About;
