import elysia from "./Lavia/elysia.webp";
import elysia2 from "./Lavia/elysia 2.webp";
import elysia3 from "./Lavia/elysia 3.webp";
import gracia from "./Lavia/cracia.webp";
import gracia2 from "./Lavia/cracia 2.webp";
import gracia3 from "./Lavia/cracia 3.webp";
import luxia from "./Lavia/luxia.webp";
import luxia2 from "./Lavia/luxia 2.webp";
import luxia3 from "./Lavia/luxia 3.webp";

const elysiaImages = [elysia, elysia2, elysia3];
const graciaImages = [gracia, gracia2, gracia3];
const luxiaImages = [luxia, luxia2, luxia3];

export { elysiaImages, graciaImages, luxiaImages };
