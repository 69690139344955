import facilities from "./Facilities (1).webp";
import facilities2 from "./Facilities (2).webp";
import facilities3 from "./Facilities (3).webp";
import facilities4 from "./Facilities (4).webp";
import facilities5 from "./Facilities (5).webp";
import facilities6 from "./Facilities (6).webp";
import facilities7 from "./Facilities (7).webp";

const facilitiesImages = [
  facilities,
  facilities2,
  facilities3,
  facilities4,
  facilities5,
  facilities6,
  facilities7,
];
export default facilitiesImages;
