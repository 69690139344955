import "./Product.scss";
import CardNewlaunch from "../../Component/Card/CardNewlaunch.jsx";
import CardRumah from "../../Component/Card/CardRumah.jsx";
import dataNewlaunch from "./index.json";
import {
  aeorprimeImages,
  osImages,
  saImages,
  aerolinkImages,
} from "../../Assets/Product/Ruko/index.js";
import {
  elysiaImages,
  graciaImages,
  luxiaImages,
} from "../../Assets/Product/Newlaunch/index.js";
import {
  clvImages,
  fpImages,
  cmImages,
} from "../../Assets/Product/Rumah/index.js";
import { faBed, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
const imageMap = {
  elysiaImages,
  graciaImages,
  luxiaImages,
  clvImages,
  fpImages,
  cmImages,
  aeorprimeImages,
  osImages,
  saImages,
  aerolinkImages,
};
const Product = () => {
  return (
    <section className="section-produk">
      <div id="newlaunch" className="container">
        <h2 id="newlaunch-livia" className="title">
          NEW LAUNCH
        </h2>
        <h3 className="title" style={{ color: "#BC8B62" }}>
          LIVIA LIVING
        </h3>
        <div className="container-card">
          {dataNewlaunch.cardsLivia.map((data, index) => (
            <CardNewlaunch
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              details={data.details}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
      <div className="container">
        <h2 id="rumah" className="title">
          CITRA GARDEN CITY
        </h2>
        <div className="container-card">
          {dataNewlaunch.cardsCGC.map((data, index) => (
            <CardRumah
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              timePeriod={data.timePeriod}
              firstIcon={faBed}
              descType={data.descType}
              descType2={<div className="ket">{data.descType2}</div>} // Updated to render the actual content
              details={data.details}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
      <div className="container">
        <h2 id="ruko" className="title">
          COMMERCIAL DISTRICT
        </h2>
        <div className="container-card">
          {dataNewlaunch.cardsCommercial.map((data, index) => (
            <CardRumah
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              timePeriod={"/bln"}
              descType={data.descType}
              firstIcon={faLayerGroup}
              details={data.details}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Product;
