import "./Hero.scss";
import herodesk from "../../Assets/Hero CGC.webp";
import heromob from "../../Assets/Hero CGC Mob.webp";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-container">
        <img src={herodesk} alt="Hero Citra Garden" className="img-desktop" />
        <img src={heromob} alt="Hero Citra Garden" className="img-mobile" />
      </div>
    </div>
  );
};

export default Hero;
