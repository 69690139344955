import React from "react";
import "./Facilities.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import facilitiesImages from "../../Assets/Facilities/index";
const Facilities = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="surroundingcontainer" id="fasilitas">
      <div className="title">PREMIUM FACILITIES</div>
      <div className="top">
        <div className="container-image">
          <Slider {...settings}>
            {facilitiesImages.map((item, index) => (
              <img
                src={item}
                alt="Area Sekitar Citra Garden City"
                className="image"
              />
            ))}
          </Slider>
          <div className="container-description">
            <h2>Lokasi Strategis</h2>
            <p>
              Terletak di kawasan yang berkembang pesat, aksesibilitas yang
              mudah menjadi nilai tambah bagi para pengusaha dan investor.
            </p>
            <ul>
              <li>10 menit dari Bandara Soekarno-Hatta</li>
              <li>5 menit dari Tol JORR 2</li>
              <li>3 menit dari Ciputra Hospital</li>
              <li>Dikelilingi lebih dari 50.000 kepala keluarga</li>
            </ul>
            <h2>Fasilitas yang Lengkap</h2>
            <p>
              Dari pusat perbelanjaan hingga fasilitas olahraga dan rekreasi,
              Anda akan menemukan segala yang Anda butuhkan dalam jangkauan
              Anda. Ini menjadikan investasi di CitraGarden City tidak hanya
              menguntungkan secara finansial, tetapi juga memberikan kenyamanan
              dan gaya hidup yang berkualitas.
            </p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="hook-text">
          <div className="area">450Ha</div>
          <b>Land Area</b>
        </div>
        <div className="hook-text">
          <div className="area">100+</div>
          <b>Public Facilities</b>
        </div>
        <div className="hook-text">
          <div className="area">800+</div>
          <b>Retail Outlets</b>
        </div>
      </div>
    </section>
  );
};

export default Facilities;
