import "./Location.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconHospital } from "../../Assets/svg/hospital.svg";
import { ReactComponent as IconEducation } from "../../Assets/svg/education.svg";
import { ReactComponent as IconShopping } from "../../Assets/svg/shopping.svg";
import locationImages from "../../Assets/Location/index";
import map from "../../Assets/map.png";
const location = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const pointData = {
    poin1: [
      "3 Menit ke Ciputra Hospital",
      "10 Menit ke RS Bedah Cinta Kasih Tzu Chi",
      "10 Menit ke RS Mitra Keluarga",
      "20 Menit ke Rumah Sakit Harapan Kita",
      "20 Menit ke Rumah Sakit Dharmais",
    ],
    poin2: [
      "4 Menit ke SMA Citra Kasih Jakarta",
      "6 Menit ke SMA Negeri 84 Jakarta",
      "15 Menit ke Mercu Buana University",
      "20 Menit ke Binus University",
      "20 Menit ke Podomoro University",
    ],
    poin3: [
      "1 Menit ke McDonald's",
      "1 Menit ke Starbucks",
      "1 Menit KFC",
      "1 Menit ke Magurame Udon",
      "15 Menit ke Puri Indah Mall",
    ],
  };
  return (
    <section id="location" className="section-location">
      <div className="container-location">
        <h2 className="title">location Area</h2>
        <div className="top">
          <div className="container-image">
            <Slider {...settings}>
              {locationImages.map((item, index) => (
                <img
                  src={item}
                  alt="Area Sekitar South Tresor"
                  className="image"
                />
              ))}
            </Slider>
            <img src={map} alt="Location" className="image-location" />
          </div>
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconHospital className="icon-fasilitas" />
              <span>Hospital</span>
            </div>
            {pointData.poin1.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconEducation className="icon-fasilitas" />
              <span>Education</span>
            </div>
            {pointData.poin2.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconShopping className="icon-fasilitas" />
              <span>Foods</span>
            </div>
            {pointData.poin3.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-loc"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default location;
