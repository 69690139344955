import "./Footer.scss";
import logo from "../../Assets/CGB-logo2.webp";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="section-footer">
      <div className="line1"></div>
      <div className="container-footer">
        <div className="footer-top">
          <div className="footer-logo">
            <HashLink smooth to="/">
              <img src={logo} alt="logo Citra Garden City" />
            </HashLink>
            <small>Powered by : Linktown</small>
          </div>

          <div className="footer-sitemap">
            <div className="footer-site-link">
              <div className="footer-menu">
                <h3 className="footer-logo-title">Marketing Gallery</h3>
                <div className="footer-logo-text">Citra Garden City </div>
                <div className="footer-logo-text">
                  Jl. Citra Garden City 6 No.7, RT.7/RW.5, Tegal Alur, Kec.
                  Kalideres, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta
                  11820
                </div>
                <div className="footer-contact">
                  Contact Us : +6285212538280
                </div>
                <a href="/privacy-policy" className="footer-logo-text">
                  Privacy Policy
                </a>
                <li className="nav-item" onClick={scrollToTop}>
                  <a href="/" className="nav-links">
                    Back to Top
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line2"></div>
    </footer>
  );
};

export default Footer;
