import "./CardRumah.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMaximize,
  faBed,
  faShower,
  faHouse,
  faLayerGroup,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CardRumah = ({
  carousel,
  cardID,
  title,
  downPaymentType,
  price,
  details,
  firstIcon,
  whatsappLink,
  descType,
  descType2,
  timePeriod,
}) => {
  const settings = {
    dots: carousel.length > 1,
    arrows: carousel.length > 1,
    infinite: carousel.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="card-rumah" id={cardID}>
      <Slider {...settings}>
        {carousel.map((image, index) => (
          <img
            src={image}
            alt={`Launching ${index + 1}`}
            className="card-img"
            key={index}
          />
        ))}
      </Slider>
      <div className="card-description">
        <div className="card-top">
          <h5 className="card-title">{title}</h5>
          <div className="card-sub">
            <span>
              <FontAwesomeIcon icon={faLocationDot} className="icon" />{" "}
            </span>
            {downPaymentType}
          </div>
        </div>
        <div className="card-price">
          <div className="ket">{descType}</div>

          <div>
            {descType2}
            <span>{price}</span>
            {timePeriod}
          </div>
        </div>
        <div className="gridspek">
          <div className="spek-item">
            <FontAwesomeIcon icon={firstIcon} className="icon" />
            <span>: {details.bedRoom}</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faShower} className="icon" />
            <span>: {details.bathRoom}</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faMaximize} className="icon" />
            <span>: {details.areaSize}m²</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faHouse} className="icon" />
            <span>: {details.buildingSize}m²</span>
          </div>
        </div>
        <div className="container-btn">
          <button
            className="btn"
            onClick={() => window.open(whatsappLink, "_blank")}
          >
            <FontAwesomeIcon icon={faWhatsapp} className="icon" />
            CONTACT US
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardRumah;
