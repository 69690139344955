import "./Promo.scss";
import flyer from "../../Assets/promo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Whatsapp } from "../../Assets/svg/whatsapp.svg";
const Promo = () => {
  const pointData = {
    poin1: [
      "SIAP HUNI",
      "FREE PPN",
      "FREE DP",
      "FREE LIFT",
      "FREE AC",
      "FREE KITCHEN SET",
    ],
    poin2: [
      "SUBSIDI BIAYA AKAD",
      "FREE BIAYA BPHTB",
      "FREE BIAYA AJB",
      "FREE SMARTHOME SYSTEM",
      "SOLAR WATER HEATER",
      "3 YEARS WARRANTY",
    ],
  };
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6285212538280&text=Halo+Dennys,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Promo%20Citra%20Garden%20City)%20https://citragardencity.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section id="promo" className="section-promo">
      <div className="container">
        <div className="flyer">
          <img src={flyer} alt="promo flyer" />
        </div>
        <div className="highlights">
          <h4>PROMO KESELURUHAN CITRA GARDEN CITY</h4>
          <div className="highlights-bottom">
            <div className="msg-hook">
              <div className="container-point">
                {pointData.poin1.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="container-point">
                {pointData.poin2.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>

              <div className="container-btn">
                <div className="term">*T&C apply</div>
                <button onClick={waOpen} className="btn">
                  <Whatsapp className="icon" />
                  GET THE OFFER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promo;
