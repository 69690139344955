import aeorprime from "./Aero-Prime.webp";
import aeorprime2 from "./Aero-Prime-2.webp";
import sa from "./Sunset Avenue.webp";
import sa2 from "./Sunset Avenue 2.webp";
import sa3 from "./Sunset Avenue 3.webp";
import os from "./Oriental Square.webp";
import aerolink from "./Aerolink.webp";

const aeorprimeImages = [aeorprime, aeorprime2];
const saImages = [sa, sa2, sa3];
const osImages = [os, os];
const aerolinkImages = [aerolink, aerolink];

export { aeorprimeImages, saImages, osImages, aerolinkImages };
