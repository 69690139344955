import "./Navbar.scss";
import { useState } from "react";
import logo from "../../Assets/Logo-CGC-Putih-300px.png";
import { NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6285212538280&text=Halo+Dennys,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Citra%20Garden%20City)%20https://citragardencity.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Logo South Tresor" />
        </a>
        <ul className={isMenuOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavHashLink smooth to="#about" className="nav-links">
              About Us
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#promo" className="nav-links">
              Promo
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#newlaunch-livia" className="nav-links">
              Newlaunch
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#rumah" className="nav-links">
              Residentials
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#fasilitas" className="nav-links">
              Facilities
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#location" className="nav-links">
              Location
            </NavHashLink>
          </li>
        </ul>
        <div className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </div>
        <button onClick={waOpen} className="btn-navbar">
          Contact Us
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
