import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="privacy-line">
        <h1>Privacy Policy</h1>
        <h2>Your Privacy Matters</h2>
        <p>
          Linktown respects your privacy and is committed to protecting your
          personal information. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you visit our
          website and interact with our marketing materials.
        </p>
        <h2>Information We Collect</h2>
        <ul>
          We may collect the following types of personal information such as:
          <li>
            Personal Information: Name, email address, phone number, and other
            contact information you voluntarily provide.
          </li>
          <li>
            Usage Data: Information about how you interact with our website,
            such as your IP address, browser type, device information, and pages
            visited.
          </li>
        </ul>
        <h2>How We Use Your Information</h2>
        <ul>
          We may use your personal information for the following purposes:
          <li>
            Marketing Communications: To send you marketing emails, newsletters,
            and other promotional materials.
          </li>
          <li>
            Customer Support: To respond to your inquiries and provide customer
            support.
          </li>
          <li>
            Website Improvement: To analyze website usage and improve user
            experience.
          </li>
          <li>Legal Compliance: To comply with legal obligations.</li>
        </ul>
        <h2>Sharing Your Information</h2>
        <ul>
          We may share your personal information with:
          <li>
            Service Providers: Third-party service providers who assist us in
            operating our website and providing marketing services
          </li>
          <li>
            Legal Authorities: If required by law or to protect our rights.
          </li>
        </ul>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the new Privacy Policy on
          our website.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
